<template>
  <div style="padding: 0 10px;">
    <div class="LP_box0">
      <!-- 切换 -->
      <div class="flex_lr">
        <div class="nav flex_center hand" @click="changeNav(0)" :style="nav==0?'background:#1332B7;color:#ffffff;':''">MT</div>
        <div class="nav flex_center hand" @click="changeNav(1)" :style="nav==1?'background:#05224A;color:#ffffff;':''">ULME</div>
      </div>
      <!-- 钱包余额 -->
      <div class="flex_lr" v-if="addressToken!=''">
          <div class="flex_row" style="width: 0;flex: 1;color: #e0e0e0;margin: 10px;">
            <div >{{nTypeName+$t('LP.part0.title')}}</div>
            <div id="copy" class="address">{{$setWidth=='100%'?addressToken.slice(0,5)+'...'+addressToken.slice(addressToken.length-5):addressToken.slice(0,10)+'...'+addressToken.slice(addressToken.length-10)}}</div>
          </div>
          <i class="ifont icone-fuzhi-11" @click="copy" style="color: #00FFEA;margin-left: 5px;"></i>
          
        </div>
      <div class="LP_title">{{$t('LP.part1.title')}}</div>

      <div class="LP_box1">
        <div class="LP_text flex_lr">
          <div>{{$t('LP.part1.text1')}}</div>
          <div class="LP_num">{{info.usdt}}$</div>
        </div>
        <div class="LP_text flex_lr">
          <div>{{nTypeName+$t('LP.part1.text2')}}</div>
          <div class="LP_num">{{info.token+nTypeName}}</div>
        </div>
        <!-- <div class="LP_text flex_lr">
          <div>{{$t('LP.part1.text3')}}</div>
          <div class="LP_num">≈ 123456.00USDT</div>
        </div> -->
        <div class="LP_text flex_lr">
          <div>{{nTypeName+$t('LP.part1.text4')}}</div>
          <div class="LP_num">1{{nTypeName}} ≈ {{info.price}}USDT</div>
        </div>
      </div>
       <!-- 全网MT/USDT LP当前流动池状态 -->
      <div class="LP_title">{{$t('LP.part2.title').replace("|NAME|",nTypeName)}}</div>
      <div class="LP_box1">
        <div class="flex_lr" style="padding:5px 0;">
          <div class="LP_title" style="font-size:0.8rem;width: 0;flex: 1;margin-right:10px;">{{$t('LP.part2.subtitle').replace("|NAME|",nTypeName)}}</div>
          <div>
            <div class="LP_num1">{{info.pair_usdt}}USDT</div>
            <div class="LP_num2">{{info.pair_token+nTypeName}}</div>
          </div>
        </div>
        <div class="LP_box2">
          <div class="LP_title flex_lr" style="align-items:flex-start;padding-bottom:0;">
            <div class="" style="font-size:0.8rem;">{{$t('LP.part3.subtitle1').replace("|NAME|",nTypeName)}}</div>
            <div>
              <div class="LP_num1" style="color:#00FFEA;">{{info.mt_lp}}</div>
              <!-- <div class="LP_num2">(~0.1324USDT)</div> -->
            </div>
          </div>
          <div class="LP_title flex_lr" style="font-size:0.8rem;">
            <div>{{$t('LP.part3.subtitle2')}}</div>
            <div class="LP_num">{{info.percentage}}%</div>
          </div>
          <div class="LP_title flex_lr" style="font-size:0.8rem;">
            <div>{{$t('LP.part3.subtitle3').replace("|NAME|",nTypeName)}}</div>
            <div class="LP_num">{{info.lp}}LP</div>
          </div>
        </div>
      </div>

      <!-- 添加流动池 -->
      <div class="LP_btn hand" @click="addPop=1" style="background:#13b717;">{{$t('LP.btn1')}}(升级) LP({{info.lp}})</div>
      <div class="LP_btn hand" @click="addPop=2" style="background:rgb(24 207 207);;">{{$t('LP.btn1')}}(分红) LP({{info.newlp}})</div>
      <!-- 待领取收益 -->
      <div class="LP_box1" style="margin-bottom: 0;">
        <div class="LP_title" style="padding: 5px 0;color:#00FFEA;">{{$t('LP.part4.text1')}}</div>
        <div class="flex_lr">
          <div class="LP_title" style="display: flex;padding: 5px 0;color:#FFFC00;">{{info.lp_mt_f_new}}<div style="color:#ff0000" >MT</div></div>
          <div class="LP_title" style="display: flex;padding: 5px 0;color:#FFFC00;">{{info.lp_ulm_f_new}}<div style="color:#ff0000" >ULME</div></div>
          <div class="LP_btn2 hand" @click="take80(2)">{{$t('LP.part4.btn')}}</div>
        </div>
        <!-- <div class="flex_lr" v-if="info.lp_pending>0">
          <div class="LP_title" style="display: flex;padding: 5px 0;color:#FFFC00;">尚有未分配LP分红:{{info.lp_pending}}<div style="color:#ff0000" >MT</div></div>
          
        </div>
        <div class="LP_btn2 hand" style="background: #55ff5a;color: #3a2ac3;" v-if="info.lp_pending>0" @click="lpPending">我来分配</div> -->
      </div>
      <!-- 待领取收益(升级) -->
      <div class="LP_box1" style="margin-bottom: 0;margin-top: 10px;" v-if="info.lp_mt_f>0||info.lp_ulm_f>0">
        <div class="LP_title" style="padding: 5px 0;color:#00FFEA;">{{$t('LP.part4.text1')}}(升级)</div>
        <div class="flex_lr">
          <div class="LP_title" style="display: flex;padding: 5px 0;color:#FFFC00;">{{info.lp_mt_f}}<div style="color:#ff0000" >MT</div></div>
          <div class="LP_title" style="display: flex;padding: 5px 0;color:#FFFC00;">{{info.lp_ulm_f}}<div style="color:#ff0000" >ULME</div></div>
          <div class="LP_btn2 hand" @click="take80(1)">{{$t('LP.part4.btn')}}</div>
        </div>
        <!-- <div class="flex_lr" v-if="info.lp_pending>0">
          <div class="LP_title" style="display: flex;padding: 5px 0;color:#FFFC00;">尚有未分配LP分红:{{info.lp_pending}}<div style="color:#ff0000" >MT</div></div>
          
        </div>
        <div class="LP_btn2 hand" style="background: #55ff5a;color: #3a2ac3;" v-if="info.lp_pending>0" @click="lpPending">我来分配</div> -->
      </div>
      <div class="LP_btnh hand" style="background:#006f99" @click="returnLpFun(1)" v-if="info.lp>0">{{$t('LP.btn2')}}(升级)LP({{info.lp}})</div>
      <div class="LP_btnh hand" style="background:rgb(75 184 225);" @click="returnLpFun(2)" v-if="info.newlp>0">{{$t('LP.btn2')}}(分红)LP({{info.newlp}})</div>
      <div class="flex_lr" v-if="info.mt_lp>1">
        <div class="LP_btnh hand" style="background: #479b97;margin-right: 10px;" @click="addPop2=1" >{{$t('LP.btn3')}}(升级)LP</div>
        <div class="LP_btnh hand" style="background: rgb(127 71 155);margin-right: 10px;" @click="addPop2=2" >{{$t('LP.btn3')}}(分红)LP</div>
        <div class="LP_btnh hand" @click="addPop1=true" >{{$t('LP.btn4')}}LP</div>
      </div>
    </div>
    <div class="LP_box0">
      <!-- <div class="LP_title" style="font-size: 0.9rem;">{{$t('LP.part5.title')}}</div>
      <div class="LP_btn" style="background: #0C2379;padding: 10px;margin: 0;color:#00FFEA;">{{nTypeName}}-USDT LP</div> -->
      <!-- 表 -->
      <!-- <el-table :data="tableData" :header-cell-style="{background:'#0C2379',color:'#ffffff'}" style="width: 100%;overflow:auto;margin-top:10px;" cell-class-name="success-row" row-class-name="table_row" >
        <el-table-column type="index" align="center" width="50" :index="indexMethod" :label="$t('LP.part5.text1')" />
        <el-table-column prop="lp" align="center" :label="nTypeName+$t('LP.part5.text2')" />
        <el-table-column prop="wallet" align="center" :label="$t('LP.part5.text3')"/>

      </el-table> -->
    </div>
    <!-- 添加流动池弹窗 -->
    <div class="bind flex_center" v-if="addPop>0" @touchmove.prevent @mousewheel.prevent>
      <div class="mask" @click="addPop=0"></div>
      <div class="bind_bg" :style="$setWidth=='100%'?'max-width: 90%;':'max-width: 500px;'">
        <div class="bind_box">
          <div style="color: #000000;font-weight: bold;text-align: center;">{{$t('LP.btn')}}({{addPop==1?'升级':'分红'}})</div>
          <div class="bind_text flex_pj" style="margin: 10px 0 0 0">
            <div>1LP≈{{info.addlp_usdt}}USDT</div>
            <div>1LP≈{{info.addlp_token+nTypeName}}</div>
          </div>
          <input type="text" :placeholder="$t('LP.add.input')" v-model="addLp" >
          <!-- <div class="bind_text">当前1LP价值</div> -->
          <!-- <div class="bind_text">{{info.addlp_usdt}}USDT 和 {{info.addlp_token+nTypeName}}</div> -->
          <div class="bind_text">{{$t('LP.add.text1')}}</div>
          <div class="bind_text">USDT：{{info.usdt}}</div>
          <div class="bind_text">{{nTypeName+'：'+info.token}}</div>
          <div v-if="addLp!=''" class="bind_text1">{{$t('LP.add.text2').replace("|NUM|",addLp)}}</div>
          <div v-if="info.lp_usdt!=''&&info.lp_usdt>0" class="bind_text1">{{info.lp_usdt}}USDT + {{info.lp_token+nTypeName}}</div>

          <div class="flex_lr" style="width:100%;margin:10px 0;">
            <button style="width:45%;" :style="'color:'+((addPop==1?approve_mt:approve_mt_new)>100000000?'#e0e0e0':'#ff0000')" @click="approveMT">{{$t('LP.add.btn1')}}{{nTypeName}}</button>
            <!-- <button style="width:30%;" @click="">授权</button> -->
            <button style="width:45%;" :style="'color:'+((addPop==1?approve_usdt:approve_usdt_new)>10000000?'#e0e0e0':'#ff0000')"  @click="approveUSDT">{{$t('LP.add.btn1')}}USDT</button>
          </div>
          <button @click="addLpFun">{{$t('LP.add.btn2')}}</button>
        </div>
      </div>
    </div>
    <!-- 减少流动池弹窗 -->
    <div class="bind flex_center" v-if="addPop1" @touchmove.prevent @mousewheel.prevent>
      <div class="mask" @click="addPop1=false"></div>
      <div class="bind_bg" :style="$setWidth=='100%'?'max-width: 90%;':'max-width: 500px;'">
        <div class="bind_box y_center">
          <div style="color: #000000;font-weight: bold;">{{$t('LP.reduce.title')}}</div>
          <input type="text" :placeholder="$t('LP.add.input')" v-model="addLp1">
          <div class="bind_text">{{$t('LP.reduce.text')}}LP: {{info.mt_lp}}</div>

          <div class="flex_lr" style="width:100%;margin-bottom:10px;">
            <button style="width:100%;" :style="'color:'+(approve_pair>100000?'#e0e0e0':'#ff0000')"  @click="approvePair">{{$t('LP.reduce.btn1')}}LP</button>
          </div>
          <button @click="addLpFun1">{{$t('LP.reduce.btn2')}}</button>
        </div>
      </div>
    </div>
    <!-- 质押流动池弹窗 -->
    <div class="bind flex_center" v-if="addPop2>0" @touchmove.prevent @mousewheel.prevent>
      <div class="mask" @click="addPop2=0"></div>
      <div class="bind_bg" :style="$setWidth=='100%'?'max-width: 90%;':'max-width: 500px;'">
        <div class="bind_box y_center">
          <div style="color: #000000;font-weight: bold;">{{$t('LP.pledge.title')}}({{addPop2==1?'升级':'分红'}})</div>
          <input type="text" :placeholder="$t('LP.add.input')" v-model="addLp2" >
          <div class="bind_text">{{$t('LP.pledge.text')}}LP: {{info.mt_lp}}</div>
          <div class="bind_text">{{$t('LP.pledge.text2')}}: {{info.mt_lp_usdt}}</div>

          <div class="flex_lr" style="width:100%;margin-bottom:10px;">
            <button style="width:100%;" :style="'color:'+((addPop2==1?approve_pair:approve_pair_new)>100000?'#e0e0e0':'#ff0000')"  @click="approvePair">{{$t('LP.pledge.btn1')}}LP</button>
          </div>
          <button @click="addLpFun2">{{$t('LP.pledge.btn2')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import walletHelper from "@/utils/walletHelper.js"
import { Loading } from 'element-ui';
export default {
  data () {
    return {
      nav:0,
      // 添加弹窗
      addPop:0,
      addPop1:false,
      tableData:[
        
      ],
      info:{},
      is_date:false,
      date_list:[],
      chose_date:0,
      approve_mt:0,
      approve_usdt:0,
      approve_mt_new:0,
      approve_usdt_new:0,
      approve_pair:0,
      addLp:"",
      addLp2:"",
      addLp1:"",
      nType:"mt",
      nTypeName:"MT",
      pair:undefined,
      addressToken:'',
      loading:0,
      current_page:0,
      last_page:0,
      is_next:false,
      addPop2:0,
      approve_pair_new:0,
    }
  },
  computed: {
    	userInfo() {
			return this.$store.state.user.userInfo
		},
	},
  created() {
    // window.addEventListener('scroll', this.Scrollbottom);  //页面加载时监听滚动事件
  },
  destroyed() {
    // window.removeEventListener('scroll', this.Scrollbottom) //页面离开后销毁监听事件
  },
  async mounted () {
    await this.$onLaunched;
    this.init()
    this.getDateList()
  },
  methods: {
    indexMethod(index) {
      return index +1;
    },
    dd(){
        this.loading =Loading.service({
            lock: true,
            text: '交易正在处理中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    },
    init() {
      this.addressToken=walletHelper.getContractAddress(this.nType)

      // this.get('api/User/getLpUsetList', {field:this.nType,page:this.current_page}, 'POST').then(res => {
      //   console.log(res.list.data)
      //   this.is_next = false
      //   this.last_page = res.list.last_page;//分页总数
      //   this.current_page = res.list.current_page;//当前页
      //   if (this.current_page==1) {
      //     this.tableData = res.list.data
      //   }else {
      //     if(res.list.data.length>0){
      //       this.tableData = this.tableData.concat(res.list.data)
      //     }
      //   }
      // })
      this.info={
        price:0,
        usdt:0,
        token:0,
        pair_usdt:0,
        pair_token:0,
        lp:0,
        percentage:0,
      }
      this.showBlance()
    },
    Scrollbottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.next_pages()
      }
    },
    next_pages(){
      if(this.tableData!=undefined&&this.tableData.length>0){
				this.current_page++
				if (this.last_page<this.current_page) {
					return
				}
				this.init()
			}
    },
    computeLP(){
      const that=this
      if(this.addLp>0){
        that.$set(that.info,'lp_usdt','计算中')
        that.$set(that.info,'lp_token','计算中')


        walletHelper.getContract('query').methods._addLiquidity(walletHelper.getContractAddress('usdt'),walletHelper.getContractAddress(that.nType),walletHelper.toWei(this.addLp),walletHelper.toWei('999999999999'),"0","0").call().then((result)=>{
          console.log(result)

          that.$set(that.info,'lp_usdt',walletHelper.Wei(result[0]))
          that.$set(that.info,'lp_token',walletHelper.Wei(result[1]))
        })
      }
    },
    addLpinit(){
      const that=this
      walletHelper.getContract(this.nType).methods.allowance(walletHelper.getAddress(),walletHelper.getContractAddress('daoV2')).call().then(res=>{
          console.log(res)
          that.approve_mt=res
      })

      walletHelper.getUsdt().methods.allowance(walletHelper.getAddress(),walletHelper.getContractAddress('daoV2')).call().then(res=>{
          console.log(res)
          that.approve_usdt=res
      })
      walletHelper.getContract(this.nType).methods.allowance(walletHelper.getAddress(),walletHelper.getContractAddress('daoV2New')).call().then(res=>{
          console.log(res)
          that.approve_mt_new=res
      })

      walletHelper.getUsdt().methods.allowance(walletHelper.getAddress(),walletHelper.getContractAddress('daoV2New')).call().then(res=>{
          console.log(res)
          that.approve_usdt_new=res
      })
    },
    async approvePair(){
      const that=this
      // if(!that.isLoading){
      //   that.gb_loading = Loading.service(that.loading_conf)
      // }
      let text='daoV2'+(that.addPop2==1?'':'New')

      this.sendWeb3(this.pair.methods.approve(walletHelper.getContractAddress(text),"99999999999999999999999999999")).then((result)=>{
        console.log(result)
        that.getPairAllowance()
        // that.gb_loading&&that.gb_loading.close()
        that.$toast({
            title: 'LP授权成功'
        });
      })
    },
    returnLpFun(type){
      const that=this
      let obj
      if(type==1){
        obj=walletHelper.getContract('daoV2').methods.renounceLp(this.nType=='mt'?'0':'1')
      }else{
      console.log(type,'11111111111111111',walletHelper.getContract('daoV2Newbj'))

        obj=walletHelper.getContract('daoV2Newbj').methods.renounceLp(this.nType=='mt'?'0':'1',walletHelper.getContractAddress('daoV2New'))
      }
       this.sendWeb3(obj).then((result)=>{
        that.$toast('赎回成功')
        that.showBlance()
      })
    },
    addLpFun1(){
      const that=this
      if(that.addLp1==''||that.addLp1==0){
        that.$toast({
            title: '请输入要减少的数量'
        });
        return 
      }
      if(that.approve_pair==0){
        that.$toast({
            title: '请先授权再添加'
        });
      }
      try{
        let mt="0",ulm="0"
         if(that.nType=='mt'){
          mt=walletHelper.toWei(String(that.addLp1))
        }else{
          ulm=walletHelper.toWei(String(that.addLp1))
        }

        this.sendWeb3(walletHelper.getContract('dis').methods.subLP(mt,ulm)).then((result)=>{
          console.log(result)
          that.addPop1=false
          that.addLp1=''
          that.$taost('减LP成功')
          that.showBlance()
        })
      }catch(e){
        that.closeLoading('减LP错误')
        console.log('减LP错误')
        console.log(e)
      }

    },
    copy(){
      this.$copyText(this.addressToken).then(e=>{
        this.$toast(this.$t('tips.title2'))
      }, e=> {
        this.$toast(this.$t('tips.title3'))
      })
    },

    addLpFun(){
      const that=this
      if(that.addLp==''||that.addLp==0){
        that.$toast({
            title: '请输入要添加的数量'
        });
        return 
      }
      if(that.approve_mt==0||that.approve_usdt==0){
        that.$toast({
            title: '请先授权再添加'
        });
      }
      if(walletHelper.isNotTranfer()){
        return
      }
      try{
        let mt="0",ulm="0"
        if(that.nType=='mt'){
          mt=String(that.addLp)
        }else{
          ulm=String(that.addLp)
        }
 
        this.sendWeb3(walletHelper.getContract('daoV2'+(that.addPop==1?'':'New')).methods.addLiquidity(walletHelper.toWei(that.addLp),String(this.nType=='mt'?0:1))).then((result)=>{
          console.log(result)
          that.addPop=0
          that.addLp=''
          that.$toast('添加LP并质押成功')
          that.showBlance()
        })
      }catch(e){
        that.closeLoading('添加LP并质押错误')
        console.log('加LP错误')
        console.log(e)
      }
      
    },
    //废弃又开放了
    addLpFun2(){
      const that=this

        if(that.addLp2==''||that.addLp2==0){
        that.$toast({
            title: '请输入要质押的数量'
        });
        return 
      }
      if(that.approve_pair==0){
        that.$toast({
            title: '请先授权再质押'
        });
      }
        console.log(walletHelper.toWei(this.addLp2))


        this.sendWeb3(walletHelper.getContract('daoV2'+(that.addPop2==1?'':'New')).methods.userReleaseLP(walletHelper.toWei(this.addLp2),String(this.nType=='mt'?0:1))).then((result)=>{
          console.log(result)
          that.addPop2=0
          that.addLp2=''
          that.$toast('质押成功')
          that.showBlance()
        })
    },
    lpPending(){
      const that=this
      if(that.info.lp_pending==0){
        that.$toast({
            title: '无未分配:LP'
        });
        return
      }

      this.sendWeb3(walletHelper.getContract('daoV2').methods.changeLpData(this.nType=='mt'?0:1)).then((result)=>{
          console.log(result)
          that.closeLoading('分配成功')
          that.showBlance()
        })
    },
    closeLoading(msg){
      if(msg&&msg!=''){
        this.$toast({
            title: msg
        });
      }
      this.loading&&this.loading.close();
    },
    approveMT(){
      const that=this
      let text='daoV2'+(that.addPop==1?'':'New')
      this.sendWeb3(walletHelper.getContract(that.nType).methods.approve(walletHelper.getContractAddress(text),"99999999999999999999999999999")).then((result)=>{
        console.log(result)
        that.addLpinit()
        that.$toast({
            title: that.nTypeName+'授权成功'
        });
      })
    },
    approveUSDT(){
      const that=this
      this.sendWeb3(walletHelper.getUsdt().methods.approve(walletHelper.getContractAddress('daoV2'+(that.addPop==1?'':'New')),"99999999999999999999999999999")).then((result)=>{
        console.log(result)
        that.addLpinit()
        that.$toast({
            title: 'USDT授权成功'
        });
      })
    },
    showBlance(){
      const that=this
      try{
       walletHelper.getContract('query').methods.getTokenToUsdt(walletHelper.getContractAddress(that.nType),walletHelper.toWei("1")).call().then((result)=>{
        console.log('result',result)
        that.$set(that.info,'price',walletHelper.Wei(result))
      })
      }catch(e){
        console.log(e)
     
      }
      walletHelper.getContract('query').methods.getPair(walletHelper.getContractAddress(this.nType)).call().then(res=>{
          console.log('pair',res)
          that.pair=walletHelper.ERC20(res)
      })
      walletHelper.getContract('daoV2New').methods.getEverydayLp(82,1).call().then(res=>{
          console.log('daoV2New',res)
      })
       walletHelper.getUsdt().methods.balanceOf(walletHelper.getAddress()).call().then(( result)=>{
          that.$set(that.info,'usdt',walletHelper.Wei(result))
        })
       walletHelper.getContract('daoV2New').methods.getLpCount(walletHelper.getAddress()).call().then(( result)=>{
          that.$set(that.info,'lp_mt_f_new',walletHelper.Wei(result[0]))
          that.$set(that.info,'lp_ulm_f_new',walletHelper.Wei(result[1]))
        })
        walletHelper.getContract('daoV2').methods.getLpCount(walletHelper.getAddress(),'1672827598').call().then(( result)=>{
          that.$set(that.info,'lp_mt_f',0)
          that.$set(that.info,'lp_ulm_f',walletHelper.Wei(result[1]))
        })
      walletHelper.getContract(that.nType).methods.balanceOf(walletHelper.getAddress()).call().then((result)=>{
        that.$set(that.info,'token',walletHelper.Wei(result))
      })
      
      walletHelper.getContract('query').methods.getPairBalance(walletHelper.getContractAddress(that.nType)).call().then((result)=>{
        that.$set(that.info,'pair_usdt',walletHelper.Wei(result[0]))
        that.$set(that.info,'pair_token',walletHelper.Wei(result[1]))
      })
      walletHelper.getUserData(walletHelper.getAddress(),(res,e)=>{
        console.log(56655,res)

        that.$set(that.info,'lp',res[this.nType=='mt'?'userLpMt':'userLpUlm'])
        that.$set(that.info,'newlp',res[this.nType=='mt'?'userNewLpMt':'userNewLpUlm'])
        if(that.info.lp>0){
          walletHelper.getContract('query').methods.getPair(walletHelper.getContractAddress(that.nType)).call().then(res=>{
              console.log(res)
              if(res!=''){
                that.pair=walletHelper.ERC20(res)
                that.getPairAllowance()
                // that.pair.methods.totalSupply().call().then(result=>{
                //     console.log('percentage',res,that.info.lp,walletHelper.Wei(result))
                //     that.$set(that.info,'percentage',(that.info.lp/walletHelper.Wei(result)*100).toFixed(4))
                // })
                walletHelper.getContract('daoV1').methods._allPower(this.nType=='mt'?5:6).call().then(result=>{
                    console.log('percentage',res,that.info.lp,walletHelper.Wei(result))
                    that.$set(that.info,'percentage',(that.info.lp/walletHelper.Wei(result)*100).toFixed(4))
                })
              }
          })
          walletHelper.getContract('daoV2').methods.changeLpBalance(walletHelper.getContractAddress(that.nType),this.nType=='mt'?0:1).call().then(result=>{
            that.$set(that.info,'lp_pending',walletHelper.Wei(result[0]))
          })
        }

      })


      walletHelper.getContract('query').methods.getUserLp(walletHelper.getContractAddress(that.nType),walletHelper.getAddress()).call().then((result)=>{
        that.$set(that.info,'mt_lp',walletHelper.Wei(result))
        if(Number(result)>0){
          walletHelper.getContract('query').methods.computeLP(walletHelper.getContractAddress(that.nType),result).call().then((result)=>{
            that.$set(that.info,'mt_lp_usdt',walletHelper.Wei(result[0]))
          })
        }
      })
      let mt="0",ulm="0"
        if(that.nType=='mt'){
          mt='1'
        }else{
          ulm='1'
        }
      walletHelper.getContract('dis').methods.computeLP(mt,ulm).call().then((result)=>{
        let i=0
         if(that.nType!='mt'){
          i=2
        }
        that.$set(that.info,'addlp_usdt',walletHelper.Wei(result[i]))
        that.$set(that.info,'addlp_token',walletHelper.Wei(result[i+1]))
      })
      that.addLpinit()
    },
    updateUser(data){
      this.get('/api/User/update', data, 'POST').then(res => {
        this.$store.commit('user/SET_USER_INFO', res.info)
      })
    },
    computeLPUsdt(){
      if(Number(this.addLp2)>0){
        const that=this
        walletHelper.getContract('query').methods.computeLP(walletHelper.getContractAddress(that.nType),walletHelper.toWei(this.addLp2)).call().then((result)=>{
          that.$set(that.info,'mt_lp_usdt',walletHelper.Wei(result[0]))
        })
      }

    },
    take80(type){
      // if(this.userInfo.allocated_mt==0&&this.userInfo.allocated_ulm==0){
      //   this.$toast({title:'没有可领取的数额'})
      //   return
      // }
      // this.get('/api/User/take80', {account:walletHelper.getAddress()}, 'POST').then(res => {
      //   this.$store.commit('user/SET_USER_INFO', res.info)
      // })
      if(walletHelper.isNotTranfer()){
        return
      }
      const that=this
      this.sendWeb3(walletHelper.getContract('daoV2'+(type==2?'New':'bj')).methods.takeLp()).then((result)=>{
        console.log(result)
        that.closeLoading('领取成功')
        that.init()
      },e=>{
         that.closeLoading('领取失败')
      })

    },
    changeNav(nav){
      if(nav==1){
        // this.$toast(this.$t('tips.title'))
        // return
        this.nType='ulm'
        this.nTypeName='ULME'
      }else{
        this.nType='mt'
        this.nTypeName='MT'
      }
      this.addLp=''
      this.addLp2=''
      this.nav = nav
      this.init()
    },
    getPairAllowance(){
      const that=this
       this.pair.methods.allowance(walletHelper.getAddress(),walletHelper.getContractAddress('daoV2')).call().then(res=>{
          console.log(res)
          that.approve_pair=Number(res)
      })
      this.pair.methods.allowance(walletHelper.getAddress(),walletHelper.getContractAddress('daoV2New')).call().then(res=>{
          console.log(res)
          that.approve_pair_new=Number(res)
      })
    },

    // 获取日期列表
    getDateList(){
      var nowDay = new Date();
      var toDay = nowDay.getTime();
      var day = 0
      var oneDay = 1000 * 60 * 60 * 24;
      for(var a=0;a<7;a++){
        var prevDay = toDay - day
        this.date_list.push(this.getDay(prevDay))
        day += oneDay
      }
    },
    getDay(date){
      var newDate = new Date(date);
      return ((newDate.getMonth()+1)>9?(newDate.getMonth()+1):'0'+(newDate.getMonth()+1))+'-'+(newDate.getDate()>9?newDate.getDate():'0'+newDate.getDate())
    },
    // 选择日期
    choseDate(index){
      this.chose_date = index
      this.is_date = false
    }
  },
  watch: {
    addLp:{
      handler (val, oldVal) {
        if(val!=oldVal){
          this.computeLP()
        }
      },
    },
    addLp2:{
      handler (val, oldVal) {
        if(val!=oldVal){
          this.computeLPUsdt()
        }
      },
    },
  }
}
</script>
 

 <style lang="scss">
// 图表
.el-table {
  font-family: simsong;
  color: #ffffff !important;
  background: #041123;
  font-size: 0.85rem;
}
.el-table__body tr:hover > td {
  background-color: transparent !important;
}
.el-table--border .el-table__cell {
  border: none;
}
.el-table tr:nth-of-type(1n+0) {
  background: #020b1b;
}
.el-table tr:nth-of-type(2n+0) {
  background: #081850;
}
.el-table td{
  border: none !important;
}
.el-table th{
  border-bottom: none !important;
}
.el-table::before{
  width: 0 !important;
}
</style>
<style lang="scss" scoped>
  .nav{
    width: 48%;
    background: #041834;
    color: #737373;
    height: 50px;
    border-radius: 5px;
    margin: 10px 0;
    font-size: 1rem;
  }
  .LP_title{
    color: #00FFEA;
    font-size: 1rem;
    padding: 10px 0;
  }
  .LP_text{
    color: #ffffff;
    font-size: 0.85rem;
    padding: 5px 0;
  }
  .LP_num{
    color: #00FFEA;
  }
  .LP_box0{
    width: 100%;
    background: #010C1B;
    border-radius:5px;
    padding:10px;
    margin-bottom: 10px;
  }
  .LP_box1{
    width: 100%;
    background: #1332B7;
    border-radius: 8px;
    padding: 10px 15px;
    margin-bottom: 10px;
    .LP_title{
      color: #ffffff;
    }
  }
  .LP_box2{
    width: 100%;
    background: #041453;
    border-radius: 8px;
    padding: 10px 13px;
    margin-bottom: 10px;
  }
  .LP_num1{
    text-align: right;
    font-size: 0.8rem;
    color: #ffffff;
  }
  .LP_num2{
    text-align: right;
    font-size: 0.75rem;
    color: #00FFEA;
  }
  .LP_btn{
    width: 100%;
    text-align: center;
    background: #1332B7;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1rem;
    padding: 15px;
    margin: 20px 0;
    white-space: nowrap;
  }
  .LP_btnh{
    width: 100%;
    text-align: center;
    background: #990000;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1rem;
    padding: 15px;
    margin: 20px 0;
    white-space: nowrap;
  }
  .LP_btn2{
    text-align: center;
    background: #00FFEA;
    border-radius: 5px;
    color: #000000;
    font-size: 0.9rem;
    padding: 5px 25px;
    white-space: nowrap;
  }
  .date_show{
    position: relative;
    z-index: 4;
    height: 20px;
    font-size: 0.75rem;
    color: #ffffff;
    background: #0c3774;
    border-radius: 5px;
    padding: 0 8px;
    i{
      font-size: 0.75rem;
      color: #1870EA;
      transform: scale(0.7);
      margin-left: 10px;
    }
  }
  .date_list{
    position: absolute;
    z-index: 3;
    right: 0;
    left: 0;
    top: 0;
    margin: auto;
    font-size: 0.75rem;
    color: #ffffff;
    background: #0c3774;
    border-radius: 5px;
    padding: 20px 8px 0;
    .date_item{
      padding: 3px 0;
    }
  }
  .address{
      width: 0;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  .bind{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    font-family: PingFang SC;
    .bind_bg{
      background: #21C7D5;
      padding: 6px;
      border-radius: 7px;
      position: relative;
      z-index: 2;
      width: 90%;
      .bind_box{
        // background: rgba(#000000,0.7);
        z-index: 1000;
        padding: 15px 10px;
        border-radius: 7px;
        font-size: 16px;
        .bind_text{
          color: #CE0F0F;
          font-size: 14px;
          padding: 0 5px;
          margin-bottom: 5px;
        }
        .bind_text1{
          color: rgb(14, 245, 137);
          font-size: 14px;
          padding: 0 5px;
          margin-bottom: 10px;
        }
        .bind_text2{
          color: rgb(233, 99, 22);
          font-size: 14px;
          padding: 0 5px;
          margin-bottom: 10px;
        }
        input{
          font-size: 16px;
          // width: 0;
          // flex: 1;
          width: 100%;
          padding: 15px 15px;
          background: #ffffff;
          border-radius: 3px;
          // min-height: 100px;
          color: #00DEFF;
          line-height: 18px;
          margin: 15px 0 10px;
        }
        // chrome
        input::-webkit-input-placeholder { /* WebKit browsers */
          color: #999999;
          font-size:15px;
        }
        // firefox
        input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color: #999999;
          font-size:15px;
        }
        button{
          background: #ffffff;
          color: #177C85;
          font-size: 14px;
          border: none;
          // margin-left: 10px;
          border-radius: 3px;
          height: 40px;
          // width: 80px;
          width: 100%;
        }
      }
    }
  }
</style>